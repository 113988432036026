<!--
	Last modified: 2022/04/12 10:35:39
-->
<script>
import { cookieInformationData as cookieInformation } from './CookieInformation';

export default {
	name: 'CookieInformationBlocker',

	props: {
		/**
		 * The wrapper can be set to false, in which
		 * case the component will be rendered wrapper-
		 * less.
		 **/
		wrapper: {
			type: [String, Boolean],
			default: 'div',
			validate(value) {
				return value || typeof value === 'boolean';
			},
		},

		categories: {
			type: [Array, String],
			default() {
				return ['functional', 'statistic', 'marketing'];
			},
		},

		debugShowBlocker: {
			type: Boolean,
			default: undefined,
		},
	},

	computed: {
		cookieInformation() {
			return cookieInformation;
		},

		slotProps() {
			return {
				cookieInformation: this.cookieInformation,
				requiredCategories: this.computedCategories.map((category) => ({
					name: category,
					consentGiven:
						this.cookieInformation?.categories?.[category],
				})),
			};
		},

		computedCategories() {
			return (
				Array.isArray(this.categories)
					? this.categories
					: [this.categories]
			).filter(Boolean);
		},

		showBlocker() {
			if (typeof this.debugShowBlocker !== 'undefined') {
				return this.debugShowBlocker;
			}
			return !this.computedCategories.every(
				(category) => this.cookieInformation?.categories?.[category]
			);
		},
	},

	render(h) {
		let { default: content, blocker } = this.$scopedSlots;
		let { wrapper } = this;
		if (wrapper && typeof wrapper === 'boolean') {
			wrapper = 'div';
		}

		blocker = blocker?.(this.slotProps);
		if (this.showBlocker && blocker?.length) {
			if (wrapper) {
				return <wrapper>{blocker}</wrapper>;
			}
			return blocker;
		}

		content = content?.(this.slotProps);
		if (!this.showBlocker && content?.length) {
			if (wrapper) {
				return h(wrapper, this.slotProps, content);
			}
			return content;
		}
		return null;
	},
};
</script>
