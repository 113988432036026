var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CookieInformationBlocker',_vm._b({staticClass:"c-cookie-information-blocker-custom",scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._t("default")]},proxy:true},{key:"blocker",fn:function(ref){
var requiredCategories = ref.requiredCategories;
var cookieInformation = ref.cookieInformation;
return [_c('div',{staticClass:"\n\t\t\t\tc-cookie-information-blocker-custom__container\n\t\t\t\tflex flex-col\n\t\t\t\trounded-md\n\t\t\t\th-full\n\t\t\t\titems-start\n\t\t\t\tgap-layout-gutter\n\t\t\t\tw-full\n\t\t\t\tbg-primary-light\n\t\t\t\tp-md\n\t\t\t"},[_c('BaseH2',{staticClass:"flex items-center space-x-sm"},[_c('SvgWarningIcon',{staticClass:"w-sm text-text"}),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s('Indhold blokeret')}})],1),_vm._v(" "),_c('p',{staticClass:">=1024:w-1/2"},[_vm._v("\n\t\t\t\tDette element kan ikke vises, da du ikke har givet accept\n\t\t\t\ttil følgende typer af cookies:\n\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\tc-cookie-information-blocker-custom__info\n\t\t\t\t\tgrid\n\t\t\t\t\tgap-layout-gutter\n\t\t\t\t"},[_c('ul',{staticClass:"capitalize pl-sm"},_vm._l((requiredCategories.filter(
							function (e) { return !e.consentGiven; }
						)),function(consent){return _c('li',{key:("consent-" + (consent.name)),staticClass:"\n\t\t\t\t\t\t\tc-cookie-information-blocker-custom__item\n\t\t\t\t\t\t\trelative\n\t\t\t\t\t\t\tpl-xs\n\t\t\t\t\t\t\ttext-body\n\t\t\t\t\t\t",domProps:{"textContent":_vm._s(
							{
								statistic: 'Statistiske',
								functional: 'Funktionelle',
								marketing: 'Marketing',
							}[consent.name]
						)}})}),0),_vm._v(" "),_c('p',{staticClass:"\n\t\t\t\t\t\tc-cookie-information-blocker-custom__instruction\n\t\t\t\t\t\tblock\n\t\t\t\t\t"},[_vm._v("\n\t\t\t\t\tDu kan ændre dine indstillinger ved at klikke på knappen\n\t\t\t\t\therunder.\n\t\t\t\t")]),_vm._v(" "),_c('BaseButton',{staticClass:"\n\t\t\t\t\t\tw-max\n\t\t\t\t\t\th-max\n\t\t\t\t\t\tflex\n\t\t\t\t\t\titems-center\n\t\t\t\t\t\tjustify-items-center\n\t\t\t\t\t",attrs:{"aria-label":"Åbn cookie-indstillinger"},on:{"click":function($event){$event.stopPropagation();return cookieInformation.show.apply(null, arguments)}}},[_c('p',{staticClass:"block",domProps:{"textContent":_vm._s('Åbn cookie-indstillinger')}}),_vm._v(" "),_c('SvgCookieIcon',{staticClass:"hidden w-16 h-16 text-white",attrs:{"fill":"currentColor"}})],1)],1)],1)]}}],null,true)},'CookieInformationBlocker',Object.assign({}, _vm.$props, {wrapper: 'div'}),false))}
var staticRenderFns = []

export { render, staticRenderFns }